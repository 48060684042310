export const API_HOST = process.env.NODE_ENV === "production"
/*
  ? (process.env.REACT_APP_API_HOST || "https://cbs.quickreach.co")
  : (process.env.REACT_APP_API_HOST_DEV || "https://cbs.quickreach.co");
*/
/*
  ? (process.env.REACT_APP_API_HOST || "https://betacbs.quickreach.co")
  : (process.env.REACT_APP_API_HOST_DEV || "https://betacbs.quickreach.co");
*/
  ? (process.env.REACT_APP_API_HOST || "https://democbs.quickreach.co")
  : (process.env.REACT_APP_API_HOST_DEV || "https://democbs.quickreach.co");
/*
  ? (process.env.REACT_APP_API_HOST || "http://localhost:3210")
  : (process.env.REACT_APP_API_HOST_DEV || "http://localhost:3210");
*/
export const SITE_HOST = process.env.NODE_ENV === "production"
/*
  ? (process.env.REACT_APP_CLIENT_HOST || "https://betacbc.quickreach.co")
  : (process.env.REACT_APP_CLIENT_HOST_DEV || "https://betacbc.quickreach.co");
*/
/*
  ? (process.env.REACT_APP_CLIENT_HOST || "https://cbc.quickreach.co")
  : (process.env.REACT_APP_CLIENT_HOST_DEV || "https://cbc.quickreach.co");
*/
  ? (process.env.REACT_APP_CLIENT_HOST || "https://democbc.quickreach.co")
  : (process.env.REACT_APP_CLIENT_HOST_DEV || "https://democbc.quickreach.co");
/*
  ? (process.env.REACT_APP_CLIENT_HOST || "https://democbc.steerplatform.com")
  : (process.env.REACT_APP_CLIENT_HOST_DEV || "https://democbc.steerplatform.com");
*/
/*
  ? (process.env.REACT_APP_CLIENT_HOST || "http://localhost:15000")
  : (process.env.REACT_APP_CLIENT_HOST_DEV || "http://localhost:15000");
*/
export const STEER_DEV_AUTH_API = process.env.NODE_ENV === "production"
/*
  ? (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://betaapi.quickreach.co/api/v1/Access/authenticate")
  : (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://betaapi.quickreach.co/api/v1/Access/authenticate");
*/
/*
  ? (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://steerapi.quickreach.co/api/v1/Access/authenticate")
  : (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://steerapi.quickreach.co/api/v1/Access/authenticate");
*/
  ? (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://demoapi.quickreach.co/api/v1/Access/authenticate")
  : (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://demoapi.quickreach.co/api/v1/Access/authenticate");
/*
  ? (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://demoapi.steerplatform.com/api/v1/Access/authenticate")
  : (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://demoapi.steerplatform.com/api/v1/Access/authenticate");
*/
/*
  ? (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://localhost:44375/api/v1/Access/authenticate")
  : (process.env.STEER_DEVELOPER_AUTHENTICATE_API || "https://localhost:44375/api/v1/Access/authenticate");
*/
export const QR_AUTH_API = process.env.NODE_ENV === "production"
/*
  ? (process.env.QR_ACCOUNT_AUTH_API || "https://betacbs.quickreach.co/qraccountauth")
  : (process.env.QR_ACCOUNT_AUTH_API || "https://betacbs.quickreach.co/qraccountauth");
*/
/*
  ? (process.env.QR_ACCOUNT_AUTH_API || "https://cbs.quickreach.co/qraccountauth")
  : (process.env.QR_ACCOUNT_AUTH_API || "https://cbs.quickreach.co/qraccountauth");
*/
  ? (process.env.QR_ACCOUNT_AUTH_API || "https://democbs.quickreach.co/qraccountauth")
  : (process.env.QR_ACCOUNT_AUTH_API || "https://democbs.quickreach.co/qraccountauth");
/*
  ? (process.env.QR_ACCOUNT_AUTH_API || "https://democbs.steerplatform.com/qraccountauth")
  : (process.env.QR_ACCOUNT_AUTH_API || "https://democbs.steerplatform.com/qraccountauth");
*/
/*
  ? (process.env.QR_ACCOUNT_AUTH_API || "http://localhost:3210/qraccountauth")
  : (process.env.QR_ACCOUNT_AUTH_API || "http://localhost:3210/qraccountauth");
*/
export const DOCUMENTATION_HOST = process.env.NODE_ENV === "production" ? "https://docs.chartbrew.com" : "http://localhost:8080";

export const APP_VERSION = process.env.REACT_APP_VERSION;
export const ONE_ACCOUNT_ENABLED = !!process.env.REACT_APP_ONE_ACCOUNT_EXTERNAL_ID;
